exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-administradores-tsx": () => import("./../../../src/pages/administradores.tsx" /* webpackChunkName: "component---src-pages-administradores-tsx" */),
  "component---src-pages-codigo-tsx": () => import("./../../../src/pages/codigo.tsx" /* webpackChunkName: "component---src-pages-codigo-tsx" */),
  "component---src-pages-compras-tsx": () => import("./../../../src/pages/compras.tsx" /* webpackChunkName: "component---src-pages-compras-tsx" */),
  "component---src-pages-configuracoes-tsx": () => import("./../../../src/pages/configuracoes.tsx" /* webpackChunkName: "component---src-pages-configuracoes-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nova-senha-[codigo]-index-tsx": () => import("./../../../src/pages/novaSenha/[codigo]/index.tsx" /* webpackChunkName: "component---src-pages-nova-senha-[codigo]-index-tsx" */),
  "component---src-pages-recuperar-tsx": () => import("./../../../src/pages/recuperar.tsx" /* webpackChunkName: "component---src-pages-recuperar-tsx" */),
  "component---src-pages-relatorios-tsx": () => import("./../../../src/pages/relatorios.tsx" /* webpackChunkName: "component---src-pages-relatorios-tsx" */),
  "component---src-pages-termos-politica-tsx": () => import("./../../../src/pages/termos-politica.tsx" /* webpackChunkName: "component---src-pages-termos-politica-tsx" */),
  "component---src-pages-usuarios-tsx": () => import("./../../../src/pages/usuarios.tsx" /* webpackChunkName: "component---src-pages-usuarios-tsx" */)
}

